import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import { connect } from "react-redux";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { DimensionsContext } from "@Components/Pages/PageWrapper";
import { ToastStatus } from "@Components/Toast";
import { Pages } from "@Shared/Entities/Supplier/Response/enums";
import { Effected } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { fetchTender } from "@Store/Actions/Customers/tenders";
import { fetchDevice, fetchDevices } from "@Store/Actions/Hardware/devices";
import { addNotification } from "@Store/Actions/Notifications";
import { fetchCompany } from "@Store/Actions/Suppliers/companies";
import { editProposal, fetchResponse, proposalTab, publishResponse, updateResponse, } from "@Store/Actions/Suppliers/responses";
import { LikeHardwareFunded } from "@Shared/Entities/Customer/Tender/Details/enums";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import AdditionalData from "./AdditionalData";
import AdditionalLines from "./AdditionalLines";
import CustomerService from "./CustomerService";
import DataManagement from "./DM";
import GuidanceNotes from "./GuidanceNotes";
import HardwareFund from "./HardwareFund";
import styles from "./index.module.scss";
import Introduction from "./Introduction";
import MDM from "./MDM";
import Network from "./Network";
import Oob from "./Oob";
import ReviewAndSubmit from "./ReviewAndSubmit";
import Tariff from "./Tariff";
import TopBar from "./TopBar";
import { checkValidAdditionalData, checkValidAdditionalLines, checkValidCustomerService, checkValidDM, checkValidHardwareFund, checkValidMDM, checkValidNetwork, checkValidOOB, checkValidTariff, } from "./validation";
export const hideForSmallBusiness = (isSmallBusiness, page) => {
    if (!isSmallBusiness) {
        return false;
    }
    const hiddenPages = [Pages.HardwareFund, Pages.MDM, Pages.DM];
    if (!hiddenPages.includes(Number(page))) {
        return false;
    }
    return true;
};
class Proposal extends React.Component {
    constructor(props) {
        super(props);
        const { proposal } = this.props.response || {};
        let data = {};
        if (proposal !== undefined) {
            data = proposal?.data;
        }
        this.state = {
            fetching: true,
            notFound: false,
            data: data,
            errors: {},
            popout: undefined,
            height: 0,
        };
    }
    componentDidMount() {
        this.props
            .fetch(this.props.supplierId, this.props.responseId)
            .then(() => this.props.fetchTender(this.props.response.tenderId))
            .catch(() => {
            this.props.notify({
                title: "Oops",
                description: "There was a problem fetching your data",
                status: ToastStatus.Error,
            });
        })
            .finally(() => {
            this.setState({
                fetching: false,
                notFound: this.props.response === undefined,
            });
        });
    }
    componentDidUpdate(previousProps) {
        if (previousProps.response !== this.props.response) {
            this.setState({
                data: this.props.response.proposal.data,
            });
        }
        //fetch all required devices
        if (previousProps.tender?.details?.pickedHandsets !==
            this.props.tender?.details?.pickedHandsets) {
            const { pickedHandsets = [] } = this.props.tender?.details || {};
            for (const { pickedHandsets: ph } of pickedHandsets) {
                if (typeof ph === "string") {
                    this.props.fetchDevice(ph);
                }
            }
        }
        if (previousProps.tender?.priceCheckDevices !==
            this.props.tender?.priceCheckDevices) {
            const { priceCheckDevices = [] } = this.props.tender || {};
            for (const id of priceCheckDevices) {
                this.props.fetchDevice(id);
            }
        }
        if (previousProps.tender?.details?.likeHardwareFunded !==
            this.props.tender?.details?.likeHardwareFunded &&
            this.props.tender?.details?.likeHardwareFunded ===
                LikeHardwareFunded.Leasing) {
            this.props.fetchDevices();
        }
    }
    openPopout = (popout) => {
        this.setState({
            popout,
        });
    };
    closePopout = () => {
        this.setState({
            popout: undefined,
        });
    };
    previous = () => {
        const { tab = Pages.Introduction, tender } = this.props;
        let newPage = this.getPreviousPage(tab);
        while (newPage !== null &&
            hideForSmallBusiness(tender?.isSmallBusiness, newPage)) {
            newPage = this.getPreviousPage(newPage);
        }
        this.switchPage(newPage);
    };
    getPreviousPage = (currentPage) => {
        let newPage = null;
        switch (currentPage) {
            case Pages.Introduction:
                newPage = null;
                break;
            case Pages.Tariff:
                newPage = Pages.Introduction;
                break;
            case Pages.OOB:
                newPage = Pages.Tariff;
                break;
            case Pages.HardwareFund:
                newPage = Pages.OOB;
                break;
            case Pages.Network:
                newPage = Pages.HardwareFund;
                break;
            case Pages.AdditionalLines:
                newPage = Pages.Network;
                break;
            case Pages.AdditionalData:
                newPage = Pages.AdditionalLines;
                break;
            case Pages.MDM:
                newPage = Pages.AdditionalData;
                break;
            case Pages.DM:
                newPage = Pages.MDM;
                break;
            case Pages.CustomerService:
                newPage = Pages.DM;
                break;
            case Pages.ReviewAndSubmit:
                newPage = Pages.CustomerService;
                break;
        }
        return newPage;
    };
    next = () => {
        const { tab = Pages.Introduction, tender } = this.props;
        try {
            let newPage = this.getNextPage(tab);
            while (newPage !== null &&
                hideForSmallBusiness(tender?.isSmallBusiness, newPage)) {
                newPage = this.getNextPage(newPage, true);
            }
            this.setErrors({});
            this.switchPage(newPage);
        }
        catch (error_) {
            let errors = error_;
            if (tab === Pages.OOB) {
                errors = {
                    [Effected.Rack_Rate]: error_,
                };
            }
            this.setErrors(errors);
            Object.keys(error_).map((key) => {
                const error = error_[key];
                if (key === Effected.TariffComponents) {
                    this.props.notify({
                        title: "Oops",
                        description: "At least one tariff product is required",
                        status: ToastStatus.Error,
                    });
                    return;
                }
                if (typeof error === "object" && !Array.isArray(error)) {
                    if (key === Effected.DevicePrices) {
                        this.props.notify({
                            title: "Oops",
                            description: "Standard device prices are invalid",
                            status: ToastStatus.Error,
                        });
                    }
                    if (key === Effected.LeasingData) {
                        this.props.notify({
                            title: "Oops",
                            description: "Leasing device prices are invalid",
                            status: ToastStatus.Error,
                        });
                    }
                    return;
                }
                console.log(error);
                this.props.notify({
                    title: "Oops",
                    description: "Please fix",
                    status: ToastStatus.Error,
                });
            });
        }
    };
    getNextPage = (currentPage, skipValidation = false) => {
        let newPage = null;
        try {
            switch (currentPage) {
                case Pages.Introduction:
                    newPage = Pages.Tariff;
                    break;
                case Pages.Tariff:
                    newPage = Pages.OOB;
                    checkValidTariff(this.state.data);
                    break;
                case Pages.OOB:
                    const covered = CalculateUsageCovered(this.props.tender, this.props.response.proposal);
                    const hasUsage = this.props.tender.usageId !== undefined;
                    newPage = Pages.HardwareFund;
                    checkValidOOB(this.state.data, hasUsage, covered);
                    break;
                case Pages.HardwareFund:
                    newPage = Pages.Network;
                    checkValidHardwareFund(this.state.data, this.props.devices, this.props.tender);
                    break;
                case Pages.Network:
                    newPage = Pages.AdditionalLines;
                    checkValidNetwork(this.state.data);
                    break;
                case Pages.AdditionalLines:
                    newPage = Pages.AdditionalData;
                    checkValidAdditionalLines(this.state.data, this.props.response.proposal.tariffComponents, this.props.tender?.isSmallBusiness);
                    break;
                case Pages.AdditionalData:
                    newPage = Pages.MDM;
                    checkValidAdditionalData(this.state.data, this.props.response.proposal.tariffComponents);
                    break;
                case Pages.MDM:
                    newPage = Pages.DM;
                    checkValidMDM(this.state.data, this.props.response.noMDM);
                    break;
                case Pages.DM:
                    newPage = Pages.CustomerService;
                    checkValidDM(this.state.data);
                    break;
                case Pages.CustomerService:
                    newPage = Pages.ReviewAndSubmit;
                    checkValidCustomerService(this.state.data);
                    break;
                case Pages.ReviewAndSubmit:
                    newPage = null;
                    break;
            }
        }
        catch (error) {
            if (!skipValidation) {
                throw error;
            }
        }
        return newPage;
    };
    findPage = (tab) => {
        switch (tab) {
            case Pages.Introduction:
                return Introduction;
            case Pages.Tariff:
                return Tariff;
            case Pages.OOB:
                return Oob;
            case Pages.HardwareFund:
                return HardwareFund;
            case Pages.Network:
                return Network;
            case Pages.AdditionalLines:
                return AdditionalLines;
            case Pages.AdditionalData:
                return AdditionalData;
            case Pages.MDM:
                return MDM;
            case Pages.DM:
                return DataManagement;
            case Pages.CustomerService:
                return CustomerService;
            case Pages.ReviewAndSubmit:
                return ReviewAndSubmit;
        }
        return Introduction;
    };
    inputChange = (input) => {
        const { value, name: effects } = input;
        const data = {
            value,
            effects,
            id: this.props.responseId,
        };
        return this.props.edit(data);
    };
    publish = (response) => {
        const input = response || this.props.response;
        return this.props.publish(input);
    };
    save = (response, noNotification = false) => {
        this.setState({
            errors: {},
        });
        const input = response || this.props.response;
        return this.props.save(input).then(() => {
            if (noNotification) {
                return;
            }
            this.props.notify({
                title: "Saved",
                description: "Your proposal has been saved",
                status: ToastStatus.Success,
            });
        });
    };
    catchError = (error) => {
        for (const key of Object.keys(error.fails)) {
            this.props.notify({
                title: "Oops",
                description: error.fails[key],
                status: ToastStatus.Error,
            });
        }
        this.setState({
            errors: error.fails,
        });
        return Promise.reject();
    };
    switchPage = (tab) => {
        this.props.changeTab({
            id: this.props.responseId,
            tab,
        });
    };
    setErrors = (errors) => {
        this.setState({
            errors,
        });
    };
    render() {
        if (!this.props.tender || !this.props.response) {
            return React.createElement(React.Fragment, null);
        }
        if (this.state.fetching) {
            return React.createElement(LoadingFallback, null);
        }
        const { tab = Pages.Introduction } = this.props;
        const Page = this.findPage(tab);
        return (React.createElement(React.Fragment, null,
            React.createElement(TopBar, { catchError: this.catchError, data: this.state.data, devices: this.props.devices, errors: this.state.errors, inputChange: this.inputChange, next: this.next, notify: this.props.notify, page: tab, popoutOpen: this.state.popout !== undefined, previous: this.previous, publish: this.publish, reportHeight: (height) => this.setState({ height }), response: this.props.response, save: this.save, setErrors: this.setErrors, switchPage: this.switchPage, tender: this.props.tender, user: this.props.user, redirectPath: this.props.redirectPath }),
            React.createElement(Grid2, { container: true, spacing: 2, margin: 0, marginX: { xs: 2, md: 0 }, marginLeft: 2, alignItems: "stretch", className: styles.noScroll },
                React.createElement(Grid2, { xs: true, sx: {
                        overflowY: "scroll",
                        paddingY: 0,
                        height: this.context.height - this.state.height,
                    }, className: styles.noScroll },
                    React.createElement(Page, { data: this.state.data, errors: this.state.errors, switchPage: this.switchPage, inputChange: this.inputChange, save: this.save, catchError: this.catchError, notify: this.props.notify, response: this.props.response, tender: this.props.tender, user: this.props.user, devices: this.props.devices, setErrors: this.setErrors, supplier: this.props.supplier, height: this.context.height - this.state.height })),
                React.createElement(GuidanceNotes, { response: this.props.response, tender: this.props.tender, devices: this.props.devices, page: tab, popout: this.state.popout, openPopout: this.openPopout, closePopout: this.closePopout, height: this.context.height - this.state.height }))));
    }
}
Proposal.contextType = DimensionsContext;
const mapStateToProps = ({ suppliers: { responses = [], companies = [], responseTabs = [] }, customers: { tenders }, auth: { user }, hardware: { devices }, }, props) => {
    const response = responses.find(({ id }) => id === props.responseId);
    const tab = responseTabs.find(({ id }) => id === props.responseId);
    const tender = tenders.find(({ id }) => id === response.tenderId);
    const supplier = companies.find(({ id }) => id === props.supplierId);
    return {
        tender,
        response,
        tab: tab?.tab,
        user,
        devices,
        supplier,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        notify: (data) => addNotification(dispatch, data),
        fetch: (supplierId, responseId) => Promise.all([
            fetchResponse(dispatch, supplierId, responseId, true),
            fetchCompany(dispatch, supplierId),
        ]),
        fetchTender: (tenderId) => fetchTender(dispatch, tenderId),
        fetchDevice: (deviceId) => fetchDevice(dispatch, deviceId, true),
        fetchDevices: () => fetchDevices(dispatch),
        edit: (data) => editProposal(dispatch, data),
        save: (data) => updateResponse(dispatch, data),
        changeTab: (data) => proposalTab(dispatch, data),
        publish: (proposal) => publishResponse(dispatch, proposal),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Proposal);
