// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageContent--wc4Jz {\n  height: 100%;\n  overflow-y: hidden;\n}\n.pageContent--wc4Jz .proposal--VeKNg {\n  display: flex;\n  flex-direction: column;\n  height: 93%;\n}\n.pageContent--wc4Jz .proposal--VeKNg .page--xd8Rb {\n  display: flex;\n  flex: 1;\n  height: 100%;\n}\n.pageContent--wc4Jz .proposal--VeKNg .page--xd8Rb .content--MB8bD {\n  flex: 1;\n  overflow-y: scroll;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}\n\n.noScroll--Enph_ {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n.noScroll--Enph_::-webkit-scrollbar {\n  display: none;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,aAAa;EACb,OAAO;EACP,YAAY;AACd;AACA;EACE,OAAO;EACP,kBAAkB;EAClB,4EAA4E;AAC9E;;AAEA;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;AACA;EACE,aAAa;AACf","sourcesContent":[".pageContent {\n  height: 100%;\n  overflow-y: hidden;\n}\n.pageContent .proposal {\n  display: flex;\n  flex-direction: column;\n  height: 93%;\n}\n.pageContent .proposal .page {\n  display: flex;\n  flex: 1;\n  height: 100%;\n}\n.pageContent .proposal .page .content {\n  flex: 1;\n  overflow-y: scroll;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}\n\n.noScroll {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n.noScroll::-webkit-scrollbar {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "pageContent--wc4Jz",
	"proposal": "proposal--VeKNg",
	"page": "page--xd8Rb",
	"content": "content--MB8bD",
	"noScroll": "noScroll--Enph_"
};
export default ___CSS_LOADER_EXPORT___;
