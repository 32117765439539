import React from "react";
import styles from "./index.module.scss";
import Button from "@Components/Button";
import { DragAndDropUploader } from "@Components/DragAndDropUploader";
import Upload from "@Components/Icons/Upload";
import Papa from "papaparse";
import { check } from "@Api/Uploads";
import Dropdown from "@Components/Form/Dropdown";
import CSVIcon from "@Components/Icons/CSV";
import Exit from "@Components/Icons/Exit";
import dayjs from "dayjs";
const parsePromise = function (file, config) {
    return new Promise(function (complete, error) {
        Papa.parse(file, { ...config, complete, error });
    });
};
async function sha1(string_) {
    const enc = new TextEncoder();
    const hash = await crypto.subtle.digest("SHA-1", enc.encode(string_));
    return [...new Uint8Array(hash)]
        .map((v) => v.toString(16).padStart(2, "0"))
        .join("");
}
export function Manual(props) {
    const transformer = async (files) => {
        const hashes = new Set();
        for (const file of files) {
            const results = await parsePromise(file, {
                preview: 30,
            });
            const length = Math.max(...results.data.map((row) => Object.keys(row).length));
            const filtered = results.data.filter((row = {}) => Object.keys(row).length === length &&
                Object.values(row)[0] !== "-" &&
                Object.values(row).filter((r) => r !== "").length >
                    Math.min(Math.ceil(length / 2), 4));
            const headersObject = filtered.shift();
            const headers = [
                ...new Set(Object.values(headersObject).map((k) => k.replaceAll(/[^\dA-Za-z]+/g, " ").trim())),
            ].sort();
            const hash = await sha1(headers.join("::"));
            file.hash = hash;
            file.requiresDate = true;
            hashes.add(hash);
        }
        const data = await check([...hashes]);
        const uploadedFiles = files.map((file) => {
            file.requiresDate = data[file.hash]?.requiresDate ?? true;
            return file;
        });
        return uploadedFiles;
    };
    const months = [...Array.from({ length: 12 }).keys()].map((index) => {
        const m = dayjs().subtract(index, "months");
        return {
            value: index,
            label: m.format("MMM YYYY"),
        };
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${styles.card}` },
            React.createElement("div", { className: `${styles.titleContainer}` },
                React.createElement("div", { className: styles.icon },
                    React.createElement(Upload, null)),
                React.createElement("h4", { className: styles.title }, "Manually upload your Bill Reports")),
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.middle },
                    React.createElement(DragAndDropUploader, { className: styles.dragAndDrop, validate: (file) => {
                            const fileTypeRegex = /.*\.(csv|pdf)$/;
                            return fileTypeRegex.test(file.name);
                        }, submit: props.submit, transformer: transformer, content: ({ files, remove, submit, click, wait, updateFile, }) => {
                            const setDate = (id) => ({ option }) => {
                                const file = files.find((f) => f.id === id);
                                console.log(option);
                                file.date = dayjs().subtract(option.value, "months");
                                updateFile(file);
                            };
                            if (wait) {
                                return (React.createElement("div", { className: styles.body },
                                    " ",
                                    React.createElement(React.Fragment, null, "please wait...")));
                            }
                            if (files.length > 0) {
                                const alertSubmit = () => {
                                    if (files.length % 2 === 0 ||
                                        confirm("We recommend files are uploaded in pairs, however you have an odd number of files.\nDo you wish to proceed?")) {
                                        return submit();
                                    }
                                };
                                const datesRequired = files.findIndex((f) => f.requiresDate === true) >= 0;
                                return (React.createElement("div", { className: styles.body },
                                    React.createElement("div", { className: styles.titleWrapper },
                                        React.createElement("div", { className: styles.title }, "Selected for Upload"),
                                        datesRequired && (React.createElement("div", { className: styles.subtitle },
                                            React.createElement("b", null, "Important:"),
                                            " When asked, please provide accurate dates"))),
                                    React.createElement("div", { className: styles.files },
                                        React.createElement(React.Fragment, null, files.map(({ id, name, requiresDate, date, }) => (React.createElement("div", { key: id, className: styles.file },
                                            React.createElement("div", { className: styles.fileDetails },
                                                React.createElement("div", { className: styles.CSVicon },
                                                    React.createElement(CSVIcon, null)),
                                                React.createElement("p", null, name),
                                                React.createElement("span", { className: styles.exit, onClick: () => remove(id) },
                                                    React.createElement(Exit, { error: true, size: "1rem" }))),
                                            requiresDate && (React.createElement("div", { className: styles.fileDate, key: id },
                                                React.createElement(Dropdown, { options: months, placeholder: React.createElement("b", null, "NB Click the correct report month"), value: date
                                                        ? dayjs().diff(date, "months", false)
                                                        : undefined, onChange: setDate(id) })))))))),
                                    React.createElement("div", { className: styles.buttons },
                                        React.createElement(Button, { click: click }, "ADD FILES"),
                                        React.createElement(Button, { click: alertSubmit }, "UPLOAD"))));
                            }
                            return (React.createElement("div", { className: styles.body },
                                React.createElement("div", { className: styles.titleWrapper },
                                    React.createElement("div", { className: styles.title },
                                        "We need you to upload matching months",
                                        React.createElement("br", null),
                                        "Summary and Itemised\u00A0reports."),
                                    React.createElement("div", { className: styles.title }, "See our guides below for how to get these reports."),
                                    React.createElement("div", { className: styles.subtitle }, "TIP: Please upload at least 4 months of each report and name the files according to each month for more accurate results.")),
                                React.createElement("div", { className: styles.noFiles },
                                    React.createElement("h4", null,
                                        React.createElement("b", null, "Summary Report")),
                                    React.createElement("p", { className: styles.subtitle }, "You can upload as many as you\u00A0wish"),
                                    React.createElement(Button, { click: click }, "SELECT CSV FOR UPLOAD")),
                                React.createElement("div", { className: styles.noFiles },
                                    React.createElement("h4", null,
                                        React.createElement("b", null, "Itemised Reports")),
                                    React.createElement("p", { className: styles.subtitle }, "We need a matching itemised report for every summary\u00A0report"),
                                    React.createElement(Button, { click: click }, "SELECT CSVS FOR UPLOAD"))));
                        } })),
                props.bottomSlot && (React.createElement("div", { className: styles.bottom }, props.bottomSlot))))));
}
export default Manual;
