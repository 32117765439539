import dayjs from "dayjs";
import BaseEntity from "@Shared/Entities/AbstractEntities/BaseEntity";
import { ATCUnits, ManagerPackageTypes, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { ceilTo, round } from "@Shared/Helpers/Numbers";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Tender extends BaseEntity {
    static scoresCacheKey(tenderId) {
        return `tender:${tenderId}:response-scores`;
    }
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/customers/${this.customerId}/tenders/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { customerId, usageId, publishedAt, supplierDeadline, customerDeadline, details, stats, breakdown, priceCheckDevices, categories, awarded, postcode, userId, partnerId, supplierInvited, withholdResults, customerType, usageAddedLater, convenienceScoreVersion, customerServiceScoreVersion, valueScoreVersion, signalScoreVersion, tariffScoreVersion, pointsScoreVersion, margin, showSupplierNames, whitelist, changedDefaultPublishDate, section, resultsViewable, unusedAvgCostPerLine, ofcom, } = this;
        const { version } = this._data;
        return {
            ...parent,
            version,
            customerId,
            usageId,
            publishedAt,
            supplierDeadline,
            customerDeadline,
            details,
            stats,
            breakdown,
            priceCheckDevices,
            categories,
            awarded,
            postcode,
            userId,
            partnerId,
            supplierInvited,
            withholdResults,
            customerType,
            usageAddedLater,
            convenienceScoreVersion,
            customerServiceScoreVersion,
            valueScoreVersion,
            signalScoreVersion,
            tariffScoreVersion,
            pointsScoreVersion,
            margin,
            showSupplierNames,
            whitelist,
            changedDefaultPublishDate,
            section,
            resultsViewable,
            unusedAvgCostPerLine,
            ofcom,
        };
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
        };
    }
    get margin() {
        return this._data.margin ?? 0;
    }
    get customerType() {
        return this._data.customerType;
    }
    get userId() {
        return this._data.userId;
    }
    get partnerId() {
        return this._data.partnerId || undefined;
    }
    get customerId() {
        return this._data.customerId;
    }
    get usageId() {
        return this._data.usageId;
    }
    get publishedAt() {
        return this._data.publishedAt;
    }
    get supplierDeadline() {
        return this._data.supplierDeadline;
    }
    get resultsViewable() {
        return this._data.resultsViewable;
    }
    get resultsAvailable() {
        return (this.resultsViewable &&
            this.resultsViewable.isBefore(dayjs()) &&
            !this.withholdResults);
    }
    get customerDeadline() {
        return this._data.customerDeadline;
    }
    get isOpen() {
        return (this.awarded !== true &&
            this.supplierDeadline &&
            this.publishedAt &&
            this.publishedAt.isBefore(dayjs()) &&
            this.supplierDeadline.isAfter(dayjs()));
    }
    get afterDeadline() {
        return (this.awarded !== true &&
            this.supplierDeadline &&
            this.publishedAt &&
            this.publishedAt.isBefore(dayjs()) &&
            this.supplierDeadline.isBefore(dayjs()));
    }
    get details() {
        return this._data.details;
    }
    get stats() {
        return this._data.stats;
    }
    get breakdown() {
        return this._data.breakdown;
    }
    get categories() {
        return this._data.categories;
    }
    get currentCost() {
        if (this.usageId === undefined) {
            return this.details.manualCurrentCost || 0;
        }
        const cost = Number(this.stats?.totalCost);
        return Number.isNaN(cost) ? 0 : cost;
    }
    get netCost() {
        const discount = this.discounts;
        const net = round(this.currentCost - discount, 2);
        return Math.max(net, 0);
    }
    get atc() {
        const atc = this.details?.ATC || 0;
        const tariffCost = this.stats?.tariffCosts || 0;
        if (atc <= 0 || this.details.ATCUnits === undefined) {
            return 0;
        }
        if (this.details.ATCUnits === ATCUnits.Percentage) {
            return tariffCost * (atc / 100);
        }
        return atc;
    }
    get discounts() {
        return this.atc + this.techFund / 24;
    }
    get techFund() {
        const techFund = this.details.techFund;
        if (techFund === undefined || techFund <= 0) {
            return 0;
        }
        return techFund;
    }
    get priceCheckDevices() {
        return this._data.priceCheckDevices || [];
    }
    get awarded() {
        return this._data.awarded;
    }
    get postcode() {
        return this._data.postcode;
    }
    get supplierInvited() {
        return this._data.supplierInvited || [];
    }
    get withholdResults() {
        return this._data.withholdResults;
    }
    get currentCostPerLine() {
        return (this.netCost /
            (this.stats?.totalLines || this.details.totalLines || 1));
    }
    get unusedAvgCostPerLine() {
        const { unusedLineCost, unusedLines = 0 } = this.stats;
        if (unusedLineCost === undefined) {
            return;
        }
        if (unusedLines === 0) {
            return 0;
        }
        return unusedLineCost / unusedLines;
    }
    get benchmarkSavings() {
        const lines = this.stats?.usedLines || this.details?.totalLines || 0;
        if (lines === 0) {
            return 0;
        }
        const benchmarkSavings = this.currentCostPerLine * lines * (this.details?.length || 24) -
            this.benchmarkCost;
        if (benchmarkSavings > 0) {
            return benchmarkSavings;
        }
        return 0.15 * this.currentCost * (this.details?.length || 24);
    }
    get benchmarkCost() {
        const currentCostPerLine = this.currentCostPerLine === 0 ? 1000 : this.currentCostPerLine;
        const costPerLine = Math.min(currentCostPerLine * 0.9, 6.5);
        const lines = this.stats?.usedLines || this.details?.totalLines || 0;
        const dataCost = 2 * ceilTo(this.stats?.averageData, 5);
        return (costPerLine * lines + dataCost) * (this.details?.length || 24);
    }
    get unusedLinesSavings() {
        const unusedLines = this.stats?.unusedLines || 0;
        const unusedCostPerLine = this.unusedAvgCostPerLine ?? this.currentCostPerLine;
        return unusedLines * unusedCostPerLine * (this.details?.length || 24);
    }
    get totalSavings() {
        return this.benchmarkSavings + this.unusedLinesSavings;
    }
    get usageAddedLater() {
        return this._data.usageAddedLater || false;
    }
    get convenienceScoreVersion() {
        return this._data.convenienceScoreVersion;
    }
    get customerServiceScoreVersion() {
        return this._data.customerServiceScoreVersion;
    }
    get valueScoreVersion() {
        return this._data.valueScoreVersion;
    }
    get signalScoreVersion() {
        return this._data.signalScoreVersion;
    }
    get tariffScoreVersion() {
        return this._data.tariffScoreVersion;
    }
    get pointsScoreVersion() {
        return this._data.pointsScoreVersion;
    }
    get noMDM() {
        return this.details.MDM === ManagerPackageTypes.None;
    }
    get noDM() {
        return true;
    }
    get showSupplierNames() {
        return this._data.showSupplierNames || false;
    }
    get whitelist() {
        return this._data.whitelist || [];
    }
    get changedDefaultPublishDate() {
        return this._data.changedDefaultPublishDate;
    }
    get section() {
        return this._data.section;
    }
    get ofcom() {
        return this._data.ofcom || {};
    }
    get isSmallBusiness() {
        return this.details?.totalLines <= 100;
    }
}
