import baseMigrate from "@Shared/Entities/AbstractEntities/BaseEntity/migrate";
import companyMigrate from "@Shared/Entities/AbstractEntities/Company/migrate";
import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
const version1 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 1) {
        return current;
    }
    return { ...current, version: 1 };
};
const version2 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 2) {
        return current;
    }
    const { library } = current;
    return { ...current, OOBLibrary: library, version: 2 };
};
const version3 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 3) {
        return current;
    }
    let { id, offeredPlatforms, OOBLibrary } = current;
    if (id && OOBLibrary && !offeredPlatforms) {
        offeredPlatforms = Object.keys(OOBLibrary).map(Number);
    }
    return { ...current, offeredPlatforms, version: 3 };
};
const version4 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 4) {
        return current;
    }
    let { id, offeredPlatforms, OOBLibrary } = current;
    if (id && OOBLibrary && OOBLibrary["1"] !== undefined) {
        if (OOBLibrary["2"] === undefined) {
            OOBLibrary["2"] = OOBLibrary["1"];
            offeredPlatforms.push(2);
        }
        delete OOBLibrary["1"];
        offeredPlatforms = offeredPlatforms.filter((v) => v !== 1);
    }
    offeredPlatforms = [...new Set(offeredPlatforms)];
    return { ...current, offeredPlatforms, OOBLibrary, version: 4 };
};
const version5 = (current = {}) => {
    const { version = 0 } = current;
    if (version >= 5) {
        return current;
    }
    const { offeredPlatforms = [], OOBLibrary = {} } = current;
    for (const platform of offeredPlatforms) {
        const libraryObject = OOBLibrary[platform] || {};
        for (const allowanceType of Object.keys(AllowanceTypes)) {
            if (libraryObject[allowanceType] === undefined) {
                libraryObject[allowanceType] = 0;
            }
        }
        OOBLibrary[platform] = libraryObject;
    }
    return { ...current, OOBLibrary, version: 5 };
};
export default (original = {}) => {
    const baseMigrated = baseMigrate(original);
    const companyMigrated = companyMigrate(original);
    const converted = [version1, version2, version3, version4, version5].reduce((converted, f) => f(converted), original);
    const { version, OOBLibrary, MDMLibrary, DMLibrary, incumbentCanView, subDomain, socialEnterprise = false, limitedGuarantee, assetLock, offeredPlatforms, canViewSensabill = false, networkLibrary, customerServiceLibrary, } = converted;
    return {
        ...baseMigrated,
        ...companyMigrated,
        version,
        OOBLibrary,
        MDMLibrary,
        DMLibrary,
        incumbentCanView,
        subDomain,
        socialEnterprise,
        limitedGuarantee,
        assetLock,
        offeredPlatforms,
        canViewSensabill,
        networkLibrary,
        customerServiceLibrary,
    };
};
