import React from "react";
import { DisplayLargeText } from "@Components/DisplayLargeText";
import { ChangeNetwork, DEPChoices, DevicesLocked, EuCostCountries, ExistingDevices, FiveG, HighCostCountries, LeasingType, LikeHardwareFunded, ManagerPackageTypes, MDMInLine, Months, SimOnlyFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { displayBool } from "@Shared/Helpers/DisplayBool";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { getRemainingLines } from "@Shared/Helpers/TenderLines";
import displayDevices from "@SmartComponents/TenderEdit/Display/Devices";
const displayLines = (lines) => {
    return `${lines} Lines`;
};
export const lineRequirements = (details, isSmallBusiness) => [
    {
        title: "Number of voice lines with data",
        value: details.voiceLines,
        display: displayLines,
    },
    {
        title: "Number of data only lines",
        value: details.dataLines,
        display: displayLines,
    },
    {
        title: "Number of voice only lines",
        value: details.voiceOnlyLines,
        display: displayLines,
        hidden: isSmallBusiness,
    },
];
export const contractDetails = (tender) => {
    const { details } = tender;
    const { linesLeftSix } = getRemainingLines(tender);
    if (!tender.usageId) {
        return [
            {
                title: "Contract length",
                value: details.length,
                display: (value) => `${value} months`,
            },
        ];
    }
    return [
        {
            title: "Contract length",
            value: details.length,
            display: (value) => `${value} months`,
        },
        {
            title: "Vast majority of lines renew",
            value: details.vastMajorityLinesRenewal,
            display: (value) => Months[value],
        },
        {
            title: `Number of lines up for renewal in ${Months[details.vastMajorityLinesRenewal]}`,
            value: details.linesBeginning,
            display: displayLines,
        },
        {
            title: `Number of lines up for renewal between ${Months[(details.vastMajorityLinesRenewal + 1) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 3) % 12]}`,
            value: details.linesWithinThreeMonths,
            display: displayLines,
        },
        {
            title: `Are the remaining ${linesLeftSix} lines renewing between ${Months[(details.vastMajorityLinesRenewal + 4) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 6) % 12]}`,
            value: details.linesWithinSixMonths,
            display: displayBool,
        },
        {
            title: `Number of estimated voice lines with data to be added between ${Months[(details.vastMajorityLinesRenewal + 4) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 6) % 12]}`,
            value: details.estimatedLines,
            display: displayLines,
        },
        {
            title: `Number of estimated data lines to be added between ${Months[(details.vastMajorityLinesRenewal + 4) % 12]} and ${Months[(details.vastMajorityLinesRenewal + 6) % 12]}`,
            value: details.estimatedDataLines,
            display: displayLines,
        },
    ];
};
export const dataIDDandRoaming = (details, isSmallBusiness) => [
    {
        title: "Number of GB of data required",
        value: details.overallData,
        display: (value) => `${value} GB`,
    },
    {
        title: "Significant increase in data usage expected",
        value: details.increaseUsage,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Moving to Microsoft 365",
        value: details.microsoft365,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Is Teams used",
        value: details.onlineTeams,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Is Zoom used",
        value: details.zoom,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Is there an acquisition",
        value: details.dataAcquisition,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Other reasons for significant change in data usage",
        value: details.otherDataIncrease,
        hidden: isSmallBusiness,
    },
    {
        title: "Significant increase in calls from UK to other countries expected",
        value: details.iddCost,
        display: displayBool,
    },
    {
        title: "Which regions",
        value: details.iddCostCountries,
        display: (array) => array.map((value) => displayEnum(EuCostCountries, value)),
        hidden: isSmallBusiness,
    },
    {
        title: "Roaming usage expected",
        value: details.roamingCost,
        display: displayBool,
    },
    {
        title: "Which regions",
        value: details.roamingCostCountries,
        display: (array) => array.map((value) => displayEnum(EuCostCountries, value)),
        hidden: isSmallBusiness,
    },
    {
        title: "Significant increase in usage in countries without cost-reducing bolt-on expected",
        value: details.highCost,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Which countries",
        value: details.highCostCountries,
        display: (array) => array.map((value) => displayEnum(HighCostCountries, value)),
        hidden: isSmallBusiness,
    },
];
export const network = (tender, isSmallBusiness) => [
    {
        title: "Current network",
        value: tender.details.currentNetwork,
        display: (value) => displayEnum(Network, value),
    },
    {
        title: "Want to change network",
        value: tender.details.changeNetwork,
        display: (value) => displayEnum(ChangeNetwork, value),
    },
    {
        title: "Currently use more than one network",
        value: tender.details.multipleNetwork,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    //2 always hidden questions
];
export const handsets = (details, devices, isSmallBusiness) => [
    {
        title: "Keeping existing devices",
        value: details.existingDevices,
        display: (value) => displayEnum(ExistingDevices, value),
        hidden: isSmallBusiness,
    },
    {
        title: "Existing devices locked or unlocked",
        value: details.locked,
        display: (value) => displayEnum(DevicesLocked, value),
        hidden: isSmallBusiness,
    },
    {
        title: "New handsets required",
        value: details.handsets,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Would prefer additional support to",
        value: details.simOnlyFunded,
        display: (value) => displayEnum(SimOnlyFunded, value),
        hidden: isSmallBusiness,
    },
    {
        title: "All devices required to be 5G compatible",
        value: details.fiveG,
        display: (value) => displayEnum(FiveG, value),
        hidden: isSmallBusiness,
    },
    {
        title: "Selected handsets",
        value: details.pickedHandsets,
        display: (value = []) => {
            return value.map(({ pickedHandsets, num, oSSpread, }) => (React.createElement("div", { key: `${pickedHandsets}-${num}-${oSSpread}` }, displayDevices(pickedHandsets, num, oSSpread, devices))));
        },
        hidden: isSmallBusiness,
    },
    {
        title: "Device enrolment solution",
        value: details.dep,
        display: (value) => displayEnum(DEPChoices, value),
        hidden: isSmallBusiness,
    },
    {
        title: "Method of funding devices",
        value: details.likeHardwareFunded,
        display: (value) => displayEnum(LikeHardwareFunded, value),
        hidden: isSmallBusiness,
    },
    {
        title: "Preferred leasing type",
        value: details.leasingType,
        display: (value) => displayEnum(LeasingType, value),
        hidden: isSmallBusiness,
    },
];
export const MDM = (details, isSmallBusiness) => [
    {
        title: "Level of MDM required",
        value: details.MDM,
        display: (value) => displayEnum(ManagerPackageTypes, value),
        hidden: isSmallBusiness,
    },
    {
        title: "Currently have MDM",
        value: details.ownMDM,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Microsoft InTune used",
        value: details.MDMMSInTune,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Is the contract for MDM running in line with mobile phone contract",
        value: details.MDMInLine,
        display: (value) => displayEnum(MDMInLine, value),
        hidden: isSmallBusiness,
    },
    {
        title: "MDM contract ends",
        value: details.MDMEnd,
        hidden: isSmallBusiness,
    },
    {
        title: "Monthly MDM fee paid",
        value: details.MDMFee,
        display: displayBool,
        hidden: isSmallBusiness,
    },
];
export const additionalInfo = (details, isSmallBusiness) => [
    {
        title: "VAT registered",
        value: details.VATRegistered,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Charity or social enterprise",
        value: details.charitySocialEnterprise,
        display: displayBool,
        hidden: isSmallBusiness,
    },
    {
        title: "Additional information",
        value: details.extraDetails,
        display: DisplayLargeText,
        hidden: isSmallBusiness,
    },
];
