// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blue--SEB76 {\n  color: var(--alt-highlighted-text-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/CustomerService/index.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C","sourcesContent":[".blue {\n  color: var(--alt-highlighted-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue": "blue--SEB76"
};
export default ___CSS_LOADER_EXPORT___;
