// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--mCWMt {\n  font-size: 0.85rem;\n}\n\n.total--ejUD8 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1em;\n}\n.total--ejUD8 .value--UlXJu {\n  min-width: 8em;\n  padding: 0.5em 1.3em;\n  color: var(--inverted-text-color);\n  font-weight: bold;\n  text-align: right;\n  background-color: var(--selected-input-color);\n  border-radius: 5px;\n}\n\n.number--EF6Gu {\n  color: var(--alt-highlighted-text-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/OOB/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,iCAAiC;EACjC,iBAAiB;EACjB,iBAAiB;EACjB,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".table {\n  font-size: 0.85rem;\n}\n\n.total {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1em;\n}\n.total .value {\n  min-width: 8em;\n  padding: 0.5em 1.3em;\n  color: var(--inverted-text-color);\n  font-weight: bold;\n  text-align: right;\n  background-color: var(--selected-input-color);\n  border-radius: 5px;\n}\n\n.number {\n  color: var(--alt-highlighted-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--mCWMt",
	"total": "total--ejUD8",
	"value": "value--UlXJu",
	"number": "number--EF6Gu"
};
export default ___CSS_LOADER_EXPORT___;
