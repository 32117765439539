// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--CJVur {\n  font-size: 0.85rem;\n}\n.table--CJVur .name--eRcN4 {\n  max-width: 10em;\n  overflow-wrap: break-word;\n}\n.table--CJVur .row--OOdwv {\n  min-width: 10em;\n  text-align: left;\n}\n.table--CJVur .number--TyKly {\n  color: var(--alt-highlighted-text-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/BundleTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,wCAAwC;AAC1C","sourcesContent":[".table {\n  font-size: 0.85rem;\n}\n.table .name {\n  max-width: 10em;\n  overflow-wrap: break-word;\n}\n.table .row {\n  min-width: 10em;\n  text-align: left;\n}\n.table .number {\n  color: var(--alt-highlighted-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--CJVur",
	"name": "name--eRcN4",
	"row": "row--OOdwv",
	"number": "number--TyKly"
};
export default ___CSS_LOADER_EXPORT___;
