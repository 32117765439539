export var OS;
(function (OS) {
    OS[OS["Android"] = 0] = "Android";
    OS[OS["iOS"] = 1] = "iOS";
})(OS || (OS = {}));
export var Make;
(function (Make) {
    Make[Make["Apple"] = 0] = "Apple";
    Make[Make["Samsung"] = 1] = "Samsung";
    Make[Make["Huawei"] = 2] = "Huawei";
    Make[Make["Oppo"] = 3] = "Oppo";
    Make[Make["Motorola"] = 4] = "Motorola";
    Make[Make["LG"] = 5] = "LG";
    Make[Make["Doro"] = 6] = "Doro";
    Make[Make["Nokia"] = 7] = "Nokia";
    Make[Make["Sony"] = 8] = "Sony";
    Make[Make["OnePlus"] = 9] = "OnePlus";
    Make[Make["TCL"] = 10] = "TCL";
    Make[Make["Alcatel"] = 11] = "Alcatel";
    Make[Make["Google"] = 12] = "Google";
    Make[Make["Fairphone"] = 13] = "Fairphone";
    Make[Make["IMO"] = 14] = "IMO";
    Make[Make["HMD"] = 15] = "HMD";
    Make[Make["Honor"] = 16] = "Honor";
    Make[Make["Nothing"] = 17] = "Nothing";
})(Make || (Make = {}));
export var Color;
(function (Color) {
    Color[Color["White"] = 0] = "White";
    Color[Color["Black"] = 1] = "Black";
    Color[Color["Rose_Gold"] = 2] = "Rose_Gold";
    Color[Color["Silver"] = 3] = "Silver";
    Color[Color["Grey"] = 4] = "Grey";
    Color[Color["Yellow"] = 5] = "Yellow";
    Color[Color["Gold"] = 6] = "Gold";
    Color[Color["Midnight_Green"] = 7] = "Midnight_Green";
    Color[Color["Sky_Blue"] = 8] = "Sky_Blue";
    Color[Color["Green"] = 9] = "Green";
    Color[Color["Blue"] = 10] = "Blue";
    Color[Color["Coral"] = 11] = "Coral";
    Color[Color["Red"] = 12] = "Red";
    Color[Color["Pink"] = 13] = "Pink";
    Color[Color["Purple"] = 14] = "Purple";
    Color[Color["Graphite"] = 15] = "Graphite";
    Color[Color["Stone"] = 16] = "Stone";
    Color[Color["Light_Pink"] = 17] = "Light_Pink";
    Color[Color["Dark_Blue"] = 18] = "Dark_Blue";
    Color[Color["Raspberry"] = 19] = "Raspberry";
    Color[Color["Cyan"] = 20] = "Cyan";
    Color[Color["Cream"] = 21] = "Cream";
    Color[Color["Lavender"] = 22] = "Lavender";
})(Color || (Color = {}));
export var Hardware;
(function (Hardware) {
    Hardware[Hardware["Sim_Only"] = 0] = "Sim_Only";
    Hardware[Hardware["Lease"] = 1] = "Lease";
    Hardware[Hardware["Hardware_Fund"] = 2] = "Hardware_Fund";
})(Hardware || (Hardware = {}));
