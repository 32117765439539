// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--dZ5g3 {\n  font-size: 0.85rem;\n}\n\n.number--KWw9Z {\n  color: var(--alt-highlighted-text-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/AdditionalLinesTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".table {\n  font-size: 0.85rem;\n}\n\n.number {\n  color: var(--alt-highlighted-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--dZ5g3",
	"number": "number--KWw9Z"
};
export default ___CSS_LOADER_EXPORT___;
