// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SaveButton--Nk0PD {\n  display: flex;\n  justify-content: right;\n  padding: 0.7em;\n}\n\n.form--qcZ67 {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}\n\n.deleteButton--Zqs2r {\n  width: 1.12rem;\n  margin-top: 0.37rem;\n  cursor: pointer;\n}\n\n.totalWrapper--Zo9XI {\n  display: flex;\n}\n.totalWrapper--Zo9XI .filler--qR0Fk {\n  flex: 1;\n}\n.totalWrapper--Zo9XI .total--Y27p5 {\n  margin-right: 1.1em;\n  padding: 15px;\n  color: #fff;\n  font-weight: 700;\n  line-height: 16px;\n  background: var(--alt-highlighted-text-color);\n  border-radius: 4px;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/HardwareFund/LeasingPrices/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,4EAA4E;AAC9E;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;AACA;EACE,OAAO;AACT;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,6CAA6C;EAC7C,kBAAkB;AACpB","sourcesContent":[".SaveButton {\n  display: flex;\n  justify-content: right;\n  padding: 0.7em;\n}\n\n.form {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}\n\n.deleteButton {\n  width: 1.12rem;\n  margin-top: 0.37rem;\n  cursor: pointer;\n}\n\n.totalWrapper {\n  display: flex;\n}\n.totalWrapper .filler {\n  flex: 1;\n}\n.totalWrapper .total {\n  margin-right: 1.1em;\n  padding: 15px;\n  color: #fff;\n  font-weight: 700;\n  line-height: 16px;\n  background: var(--alt-highlighted-text-color);\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SaveButton": "SaveButton--Nk0PD",
	"form": "form--qcZ67",
	"deleteButton": "deleteButton--Zqs2r",
	"totalWrapper": "totalWrapper--Zo9XI",
	"filler": "filler--qR0Fk",
	"total": "total--Y27p5"
};
export default ___CSS_LOADER_EXPORT___;
