// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--sdPOT {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 0.5em;\n  font-weight: 600;\n  font-size: medium;\n}\n.header--sdPOT .buttonGrouped--rePKX {\n  display: flex;\n  gap: 1rem;\n}\n\n.tableContainer--dVa8a {\n  font-size: 0.85rem;\n}\n\n.addButton--fMKX1 {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.inputStyle--kK5p2 {\n  min-width: 5rem;\n  max-width: 8rem;\n}\n\n.filler--PHgM8 {\n  width: 1.5em;\n  margin-top: 0.3vw;\n}\n\n.checkbox--IycEo {\n  width: 5%;\n}\n\n.name--AyETC {\n  width: 17%;\n}\n\n.tooltip--t3zNk {\n  width: 5%;\n}\n\n.costCol--tY2pD {\n  background-color: beige;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Oob/DisplayTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 0.5em;\n  font-weight: 600;\n  font-size: medium;\n}\n.header .buttonGrouped {\n  display: flex;\n  gap: 1rem;\n}\n\n.tableContainer {\n  font-size: 0.85rem;\n}\n\n.addButton {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.inputStyle {\n  min-width: 5rem;\n  max-width: 8rem;\n}\n\n.filler {\n  width: 1.5em;\n  margin-top: 0.3vw;\n}\n\n.checkbox {\n  width: 5%;\n}\n\n.name {\n  width: 17%;\n}\n\n.tooltip {\n  width: 5%;\n}\n\n.costCol {\n  background-color: beige;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--sdPOT",
	"buttonGrouped": "buttonGrouped--rePKX",
	"tableContainer": "tableContainer--dVa8a",
	"addButton": "addButton--fMKX1",
	"inputStyle": "inputStyle--kK5p2",
	"filler": "filler--PHgM8",
	"checkbox": "checkbox--IycEo",
	"name": "name--AyETC",
	"tooltip": "tooltip--t3zNk",
	"costCol": "costCol--tY2pD"
};
export default ___CSS_LOADER_EXPORT___;
