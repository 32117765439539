import React from "react";
import { DisplayLargeText } from "@Components/DisplayLargeText";
import Arrow from "@Components/Icons/Arrow";
import AdditionalLinesTable from "@Components/ProposalSummary/AdditionalLinesTable";
import { DeliveryChargeMethod, LeasingType, LikeHardwareFunded, ManagerPackageTypes, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { AdditionalDataType, AnalysisMethod, BillingRelationship, ChargeMethod, DMFeatures, Effected, FreeNextDay, Frequency, ManagementFeeChargeMethod, MDMNames, MDMOS, Measurement, Months, NetworkRelationship, Platform, SmallTimeFrame, TariffType, TermLength, TimeFrame, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import GetTotalAirtimeCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalAirtimeCost";
import GetTotalLeasingCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalLeasingCost";
import GetTotalOobCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalOobCost";
import { displayBool } from "@Shared/Helpers/DisplayBool";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
import { format } from "@Shared/Helpers/Money";
import Builder from "./Builder";
import BundleTable from "./BundleTable";
import DevicePricesTable from "./DevicePricesTable";
import styles from "./index.module.scss";
import LeasePricesTable from "./LeasePricesTable";
import OOB from "./OOB";
var SummaryType;
(function (SummaryType) {
    SummaryType[SummaryType["Q_and_A"] = 0] = "Q_and_A";
    SummaryType[SummaryType["TariffTable"] = 1] = "TariffTable";
    SummaryType[SummaryType["BundleTable"] = 2] = "BundleTable";
    SummaryType[SummaryType["DevicePricesTable"] = 3] = "DevicePricesTable";
    SummaryType[SummaryType["OOB"] = 4] = "OOB";
    SummaryType[SummaryType["LeasePricesTable"] = 5] = "LeasePricesTable";
    SummaryType[SummaryType["AdditionalLines"] = 6] = "AdditionalLines";
})(SummaryType || (SummaryType = {}));
const fields = (hwFundTabTitle, tender, { proposal, meta }) => [
    {
        title: "Tariff",
        body: [
            {
                type: SummaryType["Q_and_A"],
                info: [
                    {
                        ques: "Tariff name",
                        field: Effected.PlanName,
                    },
                    {
                        ques: "Primary Network",
                        field: "primaryPlatform",
                        display: () => displayEnum(Platform, meta.primaryPlatform),
                    },
                    {
                        ques: "Tariff type",
                        field: "tariffType",
                        display: () => displayEnum(TariffType, meta.tariffType),
                    },
                ],
            },
            {
                type: SummaryType.TariffTable,
                field: Effected.TariffComponents,
            },
            {
                type: SummaryType["Q_and_A"],
                info: [
                    {
                        ques: "Monthly Supplier Discount",
                        field: Effected.Discount,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Proposal dependant on customer renewing another non-mobile product?",
                        field: Effected.ProposalDependant,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Specific changes to the customer cost or supplier GP",
                        field: Effected.SpecificChangesOrRequirements,
                    },
                    {
                        ques: "E-Sims",
                        field: Effected.ESims,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Network approval",
                        field: Effected.NetworkSupport,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Management fee",
                        field: Effected.IsManagementFee,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Management fee charge method?",
                        field: Effected.ManagementFeeChargeMethod,
                        display: (value) => displayEnum(ManagementFeeChargeMethod, value),
                    },
                    {
                        ques: proposal.managementFeeChargeMethod ===
                            ManagementFeeChargeMethod.Per_Line
                            ? "Cost per line?"
                            : "Total monthly cost?",
                        field: Effected.ManagementFee,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Minimum spend",
                        field: Effected.HasMinimumSpend,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "What is the minimum spend?",
                        field: Effected.MinimumSpend,
                        display: (value) => format(value),
                    },
                    {
                        ques: "If the minimum spend is not achieved, will the hardware fund be reduced?",
                        field: Effected.ReducedHWFund,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Anticipated effect",
                        field: Effected.ReducedEffect,
                    },
                ],
            },
        ],
    },
    {
        title: "OOB",
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Overall total",
                        field: Effected.Rack_Rate,
                        display: () => format(GetTotalOobCost(tender, proposal)),
                    },
                ],
            },
        ],
    },
    {
        title: "Bundles",
        body: [
            {
                type: SummaryType.BundleTable,
                field: Effected.Bundles,
            },
        ],
    },
    {
        title: hwFundTabTitle,
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Total hardware fund provided",
                        field: Effected.TotalFund,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Can the H/W fund be taken as cash?",
                        field: Effected.CashHW,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "How much of the H/W fund can be taken as cash?",
                        field: Effected.HWFundTaken,
                        display: (value) => format(value),
                    },
                    {
                        ques: "When will the H/W fund be available to draw down",
                        field: Effected.DrawDown,
                        display: (value) => displayEnum(TimeFrame, value),
                    },
                    {
                        ques: "Do you deduct the cost of sims from the customer H/W?",
                        field: Effected.DeductSims,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "How much is deducted per sim?",
                        field: Effected.DeductedPerSim,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Do you charge for SIM cards?",
                        field: Effected.SimCost,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "How much do you charge per sim?",
                        field: Effected.ChargePerSim,
                        display: (bool) => displayBool(bool),
                    },
                ],
            },
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Handsets supplied with profit margin?",
                        field: Effected.HandsetProfitMargin,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Are Apple devices DEP compliant?",
                        field: Effected.AppleDEP,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Device insurance",
                        field: Effected.DeviceInsurance,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Charge for deliver?",
                        field: Effected.ChargeForDelivery,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Charge per device or per shipment?",
                        field: Effected.DeliveryChargeMethod,
                        display: (value) => displayEnum(DeliveryChargeMethod, value),
                    },
                    {
                        ques: "Delivery charge amount",
                        field: Effected.DeliveryCharge,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Extra details",
                        field: Effected.ExtraDeviceDetails,
                    },
                ],
            },
            {
                type: SummaryType.DevicePricesTable,
                title: "Standard Device Prices",
                field: Effected.DevicePrices,
            },
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: `Offer ${displayEnum(LeasingType, tender.details.leasingType)} leasing?`,
                        field: Effected.OfferLeasing,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Leasing setup fee",
                        field: Effected.SetupFee,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Minimum lease term",
                        field: Effected.MinimumLeaseTerm,
                        display: (value) => displayEnum(Months, value),
                    },
                ],
            },
            {
                type: SummaryType.LeasePricesTable,
                title: "Leased Device Prices",
                field: Effected.LeasingData,
            },
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Leased device insurance required?",
                        field: Effected.LeasingRequiresInsurance,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Price dependant on pay by direct debit?",
                        field: Effected.DirectDebit,
                        display: (value) => displayBool(value),
                    },
                    {
                        ques: "Percentage cost if not using direct debit",
                        field: Effected.DirectDebitCost,
                        display: (value) => `${value}%`,
                    },
                    {
                        ques: "End of leasing lump sum payment",
                        field: Effected.LeasingLumpSum,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Additional notes",
                        field: Effected.LeasingNotes,
                    },
                ],
            },
        ],
        hidden: tender?.isSmallBusiness,
    },
    {
        title: "Network",
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Can you connect to more than one network?",
                        field: Effected.MultipleNetworks,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Other networks",
                        field: Effected.SelectNetworks,
                        display: (values) => values
                            .map((value) => displayEnum(Network, value))
                            .join(", "),
                    },
                    {
                        ques: "Billing Relationship ability",
                        field: Effected.BillingRelationship,
                        display: (value) => displayEnum(BillingRelationship, value),
                    },
                    {
                        ques: "Billing Relationship proposed",
                        field: Effected.BillingRelationshipChoice,
                        display: (value) => displayEnum(BillingRelationship, value),
                    },
                    {
                        ques: "Network Relationship",
                        field: Effected.NetworkRelationship,
                        display: (value) => displayEnum(NetworkRelationship, value),
                    },
                    {
                        ques: "Customer can keep existing sims",
                        field: Effected.KeepSims,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Is there an annual mandatory RPI price increase?",
                        field: Effected.Increases,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "What measurement is used?",
                        field: Effected.IncreaseMeasurement,
                        display: (value) => displayEnum(Measurement, value),
                    },
                    {
                        ques: "Additional percentage cost increase",
                        field: Effected.AdditionalIncrease,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Percentage",
                        field: Effected.AdditionalIncreasePercentage,
                        display: (value) => `${value}%`,
                    },
                    {
                        ques: "Mandatory annual supplier price rise",
                        field: Effected.MandatoryRise,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Percentage",
                        field: Effected.MandatoryRisePercentage,
                        display: (value) => `${value}%`,
                    },
                    {
                        ques: "Contract break clause",
                        field: Effected.BreakClause,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Additional information",
                        field: Effected.AdditionalNetworkInfo,
                        display: DisplayLargeText,
                    },
                ],
            },
        ],
    },
    {
        title: "Additional Lines",
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Will additional lines added during the contract be co-terminus?",
                        field: Effected.AdditionalLinesCoTerminus,
                        display: (bool) => displayBool(bool),
                        shown: tender?.isSmallBusiness,
                    },
                    {
                        ques: "Is time left co-terminus or full term?",
                        field: Effected.TimeLeftTerm,
                        display: (value) => displayEnum(TermLength, value),
                        hidden: tender?.isSmallBusiness,
                    },
                    {
                        ques: "How much is the hardware fund per additional line?",
                        field: Effected.HWFundPerLine,
                        display: (value) => format(value),
                        hidden: tender?.isSmallBusiness,
                    },
                    {
                        ques: "How much is the hardware fund per additional data sim?",
                        field: Effected.HWFundPerDataSim,
                        display: (value) => format(value),
                        hidden: tender?.isSmallBusiness,
                    },
                    {
                        ques: "Is HW fund pro rata?",
                        field: Effected.HWProRata,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Additional information",
                        field: Effected.AdditionalLinesInfo,
                        display: DisplayLargeText,
                    },
                ],
            },
            {
                type: SummaryType.AdditionalLines,
                title: "Additional Lines Costs",
                field: Effected.AdditionalLines,
            },
        ],
    },
    {
        title: "Additional Data",
        body: groupedTariff(proposal.tariffComponents || []).map(({ id, platform, type, lines, dataAllowance }) => {
            const { [Effected.AdditionalDataNoNewLine]: additionalDataNoNewLine, [Effected.AdditionalDataMinQty]: additionalDataMinQty, [Effected.AdditionalDataType]: additionalDataType, [Effected.AdditionalDataCost]: additionalDataCost, [Effected.AdditionalDataAuto]: additionalDataAuto, } = proposal.additionalData?.[id] || {};
            return {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Network",
                        field: Effected.AdditionalData,
                        display: () => displayEnum(Platform, platform),
                    },
                    {
                        ques: "Tariff Type",
                        field: Effected.AdditionalData,
                        display: () => type,
                    },
                    {
                        ques: "Lines",
                        field: Effected.AdditionalData,
                        display: () => lines,
                    },
                    {
                        ques: "Data Allowance",
                        field: Effected.AdditionalData,
                        display: () => `${dataAllowance} GB`,
                    },
                    {
                        ques: "Can data only be increased by adding a new line?",
                        field: additionalDataNoNewLine === undefined
                            ? 0
                            : Effected.AdditionalData,
                        display: () => displayBool(additionalDataNoNewLine),
                    },
                    {
                        ques: "Min qty of data",
                        field: additionalDataMinQty === undefined
                            ? 0
                            : Effected.AdditionalData,
                        display: () => `${additionalDataMinQty} GB`,
                    },
                    {
                        ques: "Data added per line or to total data pool",
                        field: additionalDataType === undefined
                            ? 0
                            : Effected.AdditionalData,
                        display: () => displayEnum(AdditionalDataType, additionalDataType),
                    },
                    {
                        ques: `Cost of ${additionalDataMinQty || 1} GB`,
                        field: additionalDataCost === undefined
                            ? 0
                            : Effected.AdditionalData,
                        display: () => format(additionalDataCost),
                    },
                    {
                        ques: "Is data automatically added",
                        field: additionalDataAuto === undefined
                            ? 0
                            : Effected.AdditionalData,
                        display: () => displayBool(additionalDataAuto),
                    },
                ],
            };
        }),
    },
    {
        title: "MDM",
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Do you offer MDM",
                        field: Effected.OfferMDM,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Which MDM tool are you offering?",
                        field: Effected.MDMName,
                        display: (value) => displayEnum(MDMNames, value),
                    },
                    {
                        ques: "MDM tool model",
                        field: Effected.MDMDetails,
                    },
                    {
                        ques: "What is the name of the MDM?",
                        field: Effected.OtherName,
                    },
                    {
                        ques: "The following applies to MDM solution",
                        field: Effected.MDMOS,
                        display: (value) => displayEnum(MDMOS, value),
                    },
                    {
                        ques: "Minimum commitment period to MDM solution",
                        field: Effected.MinCommitmentPeriod,
                        display: (value) => displayEnum(TimePeriods, value),
                    },
                    {
                        ques: "Minimum commitment period in months",
                        field: Effected.MinCommitmentPeriodOther,
                        display: (value) => `${value} months`,
                    },
                    {
                        ques: "Is cloud based MDM included free as part of your tariff solution?",
                        field: Effected.IsCloudFree,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Please detail cost per line per month for your cloud based MDM",
                        field: Effected.MDMLineCost,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Offer a range of MDM options?",
                        field: Effected.MDMRange,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Do you offer enterprise versions?",
                        field: Effected.EnterpriseVersions,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Alternative cloud based solution",
                        field: Effected.AlternativeSolution,
                    },
                    {
                        ques: "Additional Info",
                        field: Effected.FurtherMDM,
                        display: DisplayLargeText,
                    },
                ],
            },
        ],
        hidden: tender?.isSmallBusiness,
    },
    {
        title: "Data Management",
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Do you offer data management?",
                        field: Effected.OfferDM,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Which tool are you offering?",
                        field: Effected.DMTool,
                    },
                    {
                        ques: "Applies to OS",
                        field: Effected.DMOS,
                        display: (value) => displayEnum(MDMOS, value),
                    },
                    {
                        ques: "Features",
                        field: Effected.DMFeatures,
                        display: (vals) => vals
                            .map((value) => displayEnum(DMFeatures, value))
                            .join(", "),
                    },
                    {
                        ques: "Is your data management solution free as part of your tariff solution?",
                        field: Effected.DMFree,
                        display: displayBool,
                    },
                    {
                        ques: "Cost per line per month",
                        field: Effected.DMLineCost,
                        display: format,
                    },
                    {
                        ques: "Minimum commitment period",
                        field: Effected.DMMinCommitmentPeriod,
                        display: (value) => displayEnum(TimePeriods, value),
                    },
                    {
                        ques: "Min. commitment period in months",
                        field: Effected.DMMinCommitmentPeriodOther,
                        display: (value) => value + " months",
                    },
                    {
                        ques: "Do you offer a range of data management options?",
                        field: Effected.DMRange,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Are enterprise versions offered?",
                        field: Effected.DMEnterprise,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Data management solution alternatives",
                        field: Effected.DMAlternative,
                        display: DisplayLargeText,
                    },
                ],
            },
        ],
        hidden: tender?.isSmallBusiness,
    },
    {
        title: "Customer Service",
        body: [
            {
                type: SummaryType.Q_and_A,
                info: [
                    {
                        ques: "Account manager supplied?",
                        field: Effected.AccountManaged,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Will account manager visit site?",
                        field: Effected.AccountManagerVisit,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Account manager visit frequency",
                        field: Effected.AccountManagerVisitFrequency,
                        display: (value) => displayEnum(Frequency, value),
                    },
                    {
                        ques: "Formal complaint escalation process?",
                        field: Effected.ComplaintProcess,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Ticketing system?",
                        field: Effected.TicketSystem,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "VIP Service",
                        field: Effected.VIPService,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "VIP Customer query response time",
                        field: Effected.VIPServiceResponseTime,
                        display: (value) => value + " hours",
                    },
                    {
                        ques: "Customer query response time",
                        field: Effected.ResponseTime,
                        display: (value) => displayEnum(SmallTimeFrame, value),
                    },
                    {
                        ques: "Customer query resolution time",
                        field: Effected.ResolutionTime,
                        display: (value) => displayEnum(SmallTimeFrame, value),
                    },
                    {
                        ques: "Free swap service?",
                        field: Effected.FreeNextDay,
                        display: (value) => displayEnum(FreeNextDay, value),
                    },
                    {
                        ques: "Analysis of bills?",
                        field: Effected.OngoingAnalysis,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Bill analysis frequency",
                        field: Effected.AnalysisFrequency,
                        display: (value) => displayEnum(Frequency, value),
                    },
                    {
                        ques: "Bill analysis method",
                        field: Effected.OngoingAnalysisMethod,
                        display: (value) => displayEnum(AnalysisMethod, value),
                    },
                    {
                        ques: "Dispose of old handsets?",
                        field: Effected.DisposeHandsets,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Provide WEEE certificates?",
                        field: Effected.WEEECertificates,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Return full recycle value of disposables?",
                        field: Effected.FullRecycleValue,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Require customer to sign T&CS",
                        field: Effected.CustomTerms,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Charge for PAC codes?",
                        field: Effected.PACCodeCharge,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "PAC code charge method",
                        field: Effected.ChargeMethod,
                        display: (value) => displayEnum(ChargeMethod, value),
                    },
                    {
                        ques: "PAC code cost",
                        field: Effected.PACCost,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Charge for termination penalties?",
                        field: Effected.TerminationPenalty,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Termination penalty per line",
                        field: Effected.PenaltyPerLine,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Charge for paper billing/statements?",
                        field: Effected.PaperStatements,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Cost per bill/statement",
                        field: Effected.CostForStatements,
                        display: (value) => format(value),
                    },
                    {
                        ques: "Charge for not paying by Direct Debit?",
                        field: Effected.NonDirectDebitCharges,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Any additional charges?",
                        field: Effected.ExtraChargesInTenders,
                        display: (bool) => displayBool(bool),
                    },
                    {
                        ques: "Additional charge details",
                        field: Effected.AdditionalChargeDetails,
                    },
                    {
                        ques: "Anticipated total monthly cost",
                        field: Effected.TotalMonthlyCost,
                        display: (value) => format(value),
                    },
                ],
            },
        ],
    },
]
    .filter(({ title }) => title !== "MDM" ||
    !(tender.details.MDM === ManagerPackageTypes.None))
    .filter(({ hidden }) => !hidden);
export default class ProposalSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: {},
        };
    }
    createQandA = (object, index, index_, data = {}) => {
        return (React.createElement("ul", { key: `${index}-${index_}-qanda`, className: styles.list }, object.info.map(({ ques, field, display = (value) => value, hidden, shown, }, k) => {
            const answ = data[field];
            if ((answ !== undefined && !hidden) || shown) {
                return (React.createElement("li", { key: `${index}-${index_}-${k}`, className: styles.subitem },
                    React.createElement("div", { className: styles.question }, ques),
                    React.createElement("div", { className: styles.answer }, display(answ))));
            }
        })));
    };
    render() {
        const { details = {} } = this.props.tender;
        const likeHardwareFunded = details.likeHardwareFunded;
        const { proposal } = this.props.response;
        const totalAirTimeCost = GetTotalAirtimeCost(this.props.tender, proposal);
        let totalLeasingCost = 0;
        if (likeHardwareFunded === LikeHardwareFunded.Leasing) {
            totalLeasingCost = GetTotalLeasingCost(proposal.leasingData);
        }
        const hwFundTabName = likeHardwareFunded === LikeHardwareFunded.Leasing
            ? "Leasing"
            : likeHardwareFunded === LikeHardwareFunded.Included_in_tariff
                ? "Hardware Fund"
                : "Sim Only";
        const items = fields(hwFundTabName, this.props.tender, this.props.response).map(({ title, body, }, index) => (React.createElement("div", { key: index },
            React.createElement("li", { key: `li-${index}`, className: `${styles.mainitem} ${styles.section} ${this.state.shown[title] ? "" : styles.border}`, onClick: () => {
                    const { shown } = this.state;
                    shown[title] = !shown[title];
                    this.setState({ shown });
                } },
                title,
                React.createElement(Arrow, { size: "1em", rotate: this.state.shown[title] ? 90 : 0 })),
            React.createElement("div", { key: `div-${index}`, className: this.state.shown[title]
                    ? styles.border
                    : styles.closed }, body.map((object, index_) => {
                switch (object.type) {
                    case SummaryType.Q_and_A:
                        return this.createQandA(object, index, index_, proposal.data);
                    case SummaryType.TariffTable:
                        return (React.createElement(Builder, { key: `${index}-${index_}-builder`, data: proposal.get(object.field) || [] }));
                    case SummaryType.BundleTable:
                        return (React.createElement(BundleTable, { key: `${index}-${index_}-bundletable`, data: proposal.get(object.field) || [] }));
                    case SummaryType.DevicePricesTable:
                        return (React.createElement(DevicePricesTable, { key: `${index}-${index_}-devicepricestable`, devices: this.props.devices || [], title: object.title, data: proposal.get(object.field) || [], tender: this.props.tender }));
                    case SummaryType.LeasePricesTable:
                        return (React.createElement(LeasePricesTable, { key: `${index}-${index_}-leasepricestable`, devices: this.props.devices || [], title: object.title, data: proposal.get(object.field) || [], tender: this.props.tender }));
                    case SummaryType.AdditionalLines:
                        return (React.createElement(AdditionalLinesTable, { key: `${index}-${index_}-additionallinestable`, title: object.title, data: proposal.get(object.field) || {}, tariffComponents: proposal.tariffComponents }));
                    case SummaryType.OOB:
                        return (React.createElement(OOB, { key: `${index}-${index_}-rackrate`, data: proposal.get(Effected.Rack_Rate) || {}, proposal: proposal, tender: this.props.tender }));
                }
            })))));
        return (React.createElement("ul", { className: styles.list },
            React.createElement("li", { className: `${styles.mainitem} ${styles.title} ${styles.border}` },
                React.createElement("div", { className: styles.totals },
                    React.createElement("div", { className: styles.totalGroup },
                        React.createElement("p", null, "Total Airtime Cost"),
                        React.createElement("span", null, format(totalAirTimeCost))),
                    likeHardwareFunded === LikeHardwareFunded.Leasing && (React.createElement("div", { className: styles.totalGroup },
                        React.createElement("p", null, "Total Leasing Cost"),
                        React.createElement("span", null, format(totalLeasingCost)))))),
            items));
    }
}
