// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highlighted--DnLkI {\n  color: var(--alt-highlighted-text-color);\n}\n\n.card--pfJqE {\n  font-size: 0.85rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/HardwareFund/index.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".highlighted {\n  color: var(--alt-highlighted-text-color);\n}\n\n.card {\n  font-size: 0.85rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted": "highlighted--DnLkI",
	"card": "card--pfJqE"
};
export default ___CSS_LOADER_EXPORT___;
