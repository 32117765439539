import React from "react";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import { AnalysisMethod, ChargeMethod, Effected, FreeNextDay, Frequency, SmallTimeFrame, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import styles from "./index.module.scss";
export default class CustomerService extends React.Component {
    render() {
        return (React.createElement(Card, { title: "Customer Service" },
            React.createElement(Form, { submit: this.props.save, data: this.props.data, errors: this.props.errors, noSave: true, inputChange: this.props.inputChange, labelLeft: true, setErrors: this.props.setErrors, input: [
                    {
                        label: "Will you supply a named account manager?",
                        name: Effected.AccountManaged,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Will the account manager visit site?",
                        name: Effected.AccountManagerVisit,
                        type: InputType.SmallToggle,
                        hidden: this.props.data[Effected.AccountManaged] !==
                            true,
                    },
                    {
                        label: "How often will the account manager visit site?",
                        name: Effected.AccountManagerVisitFrequency,
                        type: InputType.DropdownEnum,
                        options: Frequency,
                        hidden: this.props.data[Effected.AccountManaged] !==
                            true ||
                            !this.props.data[Effected.AccountManagerVisit],
                    },
                    {
                        label: "Do you have a formal complaint escalation process?",
                        name: Effected.ComplaintProcess,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Do you run a ticketing system?",
                        name: Effected.TicketSystem,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "If you offer a VIP service, is it offered for this customer?",
                        name: Effected.VIPService,
                        type: InputType.SmallToggle,
                        notes: "This is where you are offering an enhanced level of service, above of your usual standard",
                    },
                    {
                        label: "What is the VIP response time for acknowledging customer queries?",
                        name: Effected.VIPServiceResponseTime,
                        type: InputType.DropdownOptions,
                        options: [
                            {
                                value: 1,
                                label: "1 hours",
                            },
                            {
                                value: 2,
                                label: "2 hours",
                            },
                            {
                                value: 3,
                                label: "3 hours",
                            },
                            {
                                value: 4,
                                label: "4 hours",
                            },
                            {
                                value: 5,
                                label: "5 hours",
                            },
                        ],
                        placeholder: "Pick a time period...",
                        hidden: !this.props.data[Effected.VIPService],
                    },
                    {
                        label: "What is the normal response time for acknowledging customer queries?",
                        name: Effected.ResponseTime,
                        type: InputType.DropdownEnum,
                        options: SmallTimeFrame,
                        placeholder: "Pick a time period...",
                        hidden: this.props.data[Effected.VIPService],
                    },
                    {
                        label: "What is the normal resolution time for customer queries?",
                        name: Effected.ResolutionTime,
                        type: InputType.DropdownEnum,
                        options: SmallTimeFrame,
                        placeholder: "Pick a time period...",
                    },
                    {
                        label: "Do you have a free next working day 24 month swap service on all handsets?",
                        name: Effected.FreeNextDay,
                        type: InputType.LargeToggle,
                        long: true,
                        toggleOptions: [
                            {
                                value: FreeNextDay.Yes,
                                label: "Yes",
                            },
                            {
                                value: FreeNextDay["Yes, excluding iPhone"],
                                label: "Yes, excluding iPhone",
                            },
                            {
                                value: FreeNextDay.No,
                                label: "No",
                            },
                        ],
                    },
                    {
                        label: "Do you provide ongoing analysis of bills?",
                        name: Effected.OngoingAnalysis,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "What is the frequency of this bill analysis?",
                        name: Effected.AnalysisFrequency,
                        type: InputType.DropdownOptions,
                        options: [
                            {
                                value: Frequency.Monthly,
                                label: "Monthly",
                            },
                            {
                                value: Frequency.Quarterly,
                                label: "Quarterly",
                            },
                            {
                                value: Frequency.Half_a_year,
                                label: "Half a year",
                            },
                            {
                                value: Frequency.Annually,
                                label: "Annually",
                            },
                            {
                                value: Frequency.Ad_hoc_on_request,
                                label: "Ad hoc on request",
                            },
                        ],
                        placeholder: "Pick a time period...",
                        hidden: !this.props.data.ongoingAnalysis,
                    },
                    {
                        label: "How is this done?",
                        name: Effected.OngoingAnalysisMethod,
                        type: InputType.DropdownOptions,
                        hidden: !this.props.data[Effected.OngoingAnalysis],
                        options: [
                            {
                                value: AnalysisMethod["In-house"],
                                label: "In-house",
                            },
                            {
                                value: AnalysisMethod.Sensabill,
                                label: "Sensabill",
                            },
                            {
                                value: AnalysisMethod.Manageabill,
                                label: "Manageabill",
                            },
                            {
                                value: AnalysisMethod["Plan.com"],
                                label: "Plan.com",
                            },
                            {
                                value: AnalysisMethod.Wandera,
                                label: "Wandera",
                            },
                            {
                                value: AnalysisMethod.Other,
                                label: "Other",
                            },
                        ],
                    },
                    {
                        label: "Do you dispose of old handsets?",
                        name: Effected.DisposeHandsets,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Will you provide WEEE certificate?",
                        name: Effected.WEEECertificates,
                        type: InputType.SmallToggle,
                        hidden: !this.props.data.disposeHandsets,
                    },
                    {
                        label: "Do you return full recycle value of device disposals to the customers hardware fund?",
                        name: Effected.FullRecycleValue,
                        type: InputType.SmallToggle,
                        hidden: !this.props.data.disposeHandsets,
                    },
                    {
                        label: "Do you require the customer to sign your own T&Cs in addition to networks T&Cs?",
                        name: Effected.CustomTerms,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Do you charge for PAC Codes?",
                        name: Effected.PACCodeCharge,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "How do you charge for this?",
                        name: Effected.ChargeMethod,
                        type: InputType.DropdownOptions,
                        options: [
                            {
                                value: ChargeMethod.Per_Account,
                                label: "Per Account",
                            },
                            {
                                value: ChargeMethod.Per_Device,
                                label: "Per Device",
                            },
                        ],
                        placeholder: "Pick an option..",
                        hidden: !this.props.data.PACCodeCharge,
                    },
                    {
                        label: "How much?",
                        name: Effected.PACCost,
                        type: InputType.Currency,
                        hidden: !this.props.data.PACCodeCharge,
                    },
                    {
                        label: (React.createElement(React.Fragment, null,
                            "Do you charge termination penalties",
                            " ",
                            React.createElement("span", { className: styles.blue }, "outside"),
                            " ",
                            "of network contractual terms?")),
                        name: Effected.TerminationPenalty,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "How much per line?",
                        name: Effected.PenaltyPerLine,
                        type: InputType.Currency,
                        hidden: !this.props.data.terminationPenalty,
                    },
                    {
                        label: "Do you charge for paper billing \\ statements?",
                        name: Effected.PaperStatements,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "How much per bill \\ statement?",
                        name: Effected.CostForStatements,
                        type: InputType.Currency,
                        hidden: !this.props.data[Effected.PaperStatements],
                    },
                    {
                        label: "Are there additional charges for not paying by direct debit?",
                        name: Effected.NonDirectDebitCharges,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "How much are these charges per bill?",
                        name: Effected.NonDirectDebitCost,
                        type: InputType.Currency,
                        hidden: !this.props.data[Effected.NonDirectDebitCharges],
                    },
                    {
                        label: "Are there any additional charges not already covered in this Tender response?",
                        name: Effected.ExtraChargesInTenders,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Please provide details of additional charges",
                        name: Effected.AdditionalChargeDetails,
                        type: InputType.LargeText,
                        hidden: !this.props.data[Effected.ExtraChargesInTenders],
                    },
                    {
                        label: "Please provide anticipated total monthly cost",
                        name: Effected.TotalMonthlyCost,
                        type: InputType.Currency,
                        hidden: !this.props.data[Effected.ExtraChargesInTenders],
                    },
                ] })));
    }
}
