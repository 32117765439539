// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--oMSFE .header--CvdL9 {\n  display: flex;\n  justify-content: flex-end;\n}\n.container--oMSFE .total--zbl6y {\n  position: fixed;\n  right: 30.2%;\n  bottom: 0;\n  width: 400px;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  color: #fff;\n  font-weight: 700;\n  background: var(--alt-highlighted-text-color);\n  border-radius: 4px;\n}\n.container--oMSFE .total--zbl6y .textLeft--CGBJm {\n  float: left;\n}\n.container--oMSFE .total--zbl6y .textRight--XwM5J {\n  float: right;\n}\n\n@media (min-width: 960px) {\n  .container--oMSFE {\n    gap: var(--standard-horizontal-padding);\n  }\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Oob/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,YAAY;EACZ,4EAA4E;EAC5E,WAAW;EACX,gBAAgB;EAChB,6CAA6C;EAC7C,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd;;AAEA;EACE;IACE,uCAAuC;EACzC;AACF","sourcesContent":[".container .header {\n  display: flex;\n  justify-content: flex-end;\n}\n.container .total {\n  position: fixed;\n  right: 30.2%;\n  bottom: 0;\n  width: 400px;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  color: #fff;\n  font-weight: 700;\n  background: var(--alt-highlighted-text-color);\n  border-radius: 4px;\n}\n.container .total .textLeft {\n  float: left;\n}\n.container .total .textRight {\n  float: right;\n}\n\n@media (min-width: 960px) {\n  .container {\n    gap: var(--standard-horizontal-padding);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--oMSFE",
	"header": "header--CvdL9",
	"total": "total--zbl6y",
	"textLeft": "textLeft--CGBJm",
	"textRight": "textRight--XwM5J"
};
export default ___CSS_LOADER_EXPORT___;
